// import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as React from "react"
import campaignLogo from "../images/your-zoo-needs-you-logo/yzny-logo-h-black.svg"
import oregonZooLogo from "../images/logos/ozf-logo.png"
import kgwLogo from "../images/logos/kgw-brown.png"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "./header.module.scss"
import DonateButton from "./donate-button.js"

const Header = ({ siteTitle }) => {
  const backgroundImageURL = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "textures/giraffe-pattern2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `).placeholderImage.childImageSharp.fluid.src

  return (
    <header
      style={{
        backgroundImage: `url( ${backgroundImageURL} )`,
        backgroundSize: "cover",
      }}
    >
      <div className={styles.container}>
        <div className={styles.campaignLogo}>
          <div className={styles.logoBox}>
            <img src={campaignLogo} alt="Your Zoo Needs You" />
          </div>
        </div>
        <div className={[styles.ozLogo, styles.sponsorLogo].join(" ")}>
          <img src={oregonZooLogo} alt="Oregon Zoo" />
        </div>
        <div className={[styles.kgwLogo, styles.sponsorLogo].join(" ")}>
          <img src={kgwLogo} alt="KGW" />
        </div>

        <div className={styles.sell}>
          <h1>Support the Oregon Zoo today!</h1>
          <DonateButton />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
