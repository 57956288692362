import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "./donate-button.module.scss"
import Button from "./button.js"

function DonateButton(data) {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            donation_url
          }
        }
      }
    `
  )

  // const post = props.data.markdownRemark
  return (
    <Button
      href={site.siteMetadata.donation_url}
      className={styles.donateButton}
    >
      Donate Now
    </Button>
  )
}
export default DonateButton
