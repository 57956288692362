import * as styles from "./footer.module.scss"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  // get url from config
  const { site } = useStaticQuery(
    graphql`
      query FooterInfo {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  const { title, description } = site.siteMetadata

  return (
    <div className={styles.footer}>
      <h1>{title}</h1>
      <p className={styles.description}>{description}</p>
      <p className={styles.copyright}>
        Copyright &copy;{new Date().getFullYear()} KGW &bull; A TEGNA Company |
        v1.1c
      </p>
    </div>
  )
}
export default Footer
