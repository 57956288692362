import React from "react"
import * as styles from "./button.module.scss"

const Button = props => {
  return (
    <div className={styles.button}>
      <a href={props.href}>{props.children || "OK"}</a>
    </div>
  )
}
export default Button
